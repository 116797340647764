:root {
    --header-height: 7.5vh;
}

.slide-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: baseline;
    z-index: 5;
    height: var(--header-height);
}

.slide-header-annotation {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: baseline;
    width: 100vw;
    z-index: 5;
}

.slide-header div {
    display: flex;
    flex-flow: row nowrap;
}

.slide-header h1 {
    color: var(--esa-primary-plus-2-colour);
    text-transform: uppercase;
    margin: 6.5rem 0 0 4rem;
}

/* AUDIO */
.audio-button {
	height: 5rem;
	width: 5rem;
	position: relative;
	top: 6rem;
	cursor: pointer;
}

/* GET ANNOTATION ARROWS IN THE RIGHT PLACE */
@media screen and (min-width: 768px) and (max-width: 1920px) {
    .audio-on-off {
        position: relative;
        top: 5.5rem;
        right: 7.5rem;
    }

    .exit-story-container {
        position: relative;
        top: 5vh;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2600px) {
    .audio-on-off {
        position: relative;
        top: 5.5rem;
        right: 7.5rem;
    }
}

/* TO BE FIXED */
@media screen and (min-width:2601px) {
    .audio-on-off {
        position: relative;
        top: 5.5rem;
        right: 7.5rem;
    }
}


.exit-btn {
	position: relative;
	z-index: 5;
	right: 5rem;
	height: 4rem;
	width: 4rem;
}

.exit-btn button {
	background: none;
	border: 0;
	cursor: pointer;
	height: 4rem;
	width: 4rem;
	color: var(--esa-light-colour);
	font-size: 3rem;
}