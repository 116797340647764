.left-arrow img,
.right-arrow img {
    height: 7rem;
	width: 7rem;
}

.left-arrow {
    position: absolute;
    left: 5rem;
    top: 1rem;
	margin-left: 4rem;
	margin-top: 4rem;
	cursor: pointer;
}

.right-arrow {
    position: absolute;
    right: 5rem;
    top: 5rem;
    cursor: pointer;
}

/**
 * This is the navigation line
 */
.navigation-full-width {
	height: 52vh;
}

.navigation-buttons-container {
    position: absolute;
    z-index: 15;
    width: 100vw;
}

.navigation-buttons-container-right {
    position: absolute;
    z-index: 15;
    width: 50vw;
}

.navigation-buttons-container-left {
    position: absolute;
    z-index: 15;
    left: 0px;
    width: 50vw;
}

.annotation-next-container {
    position: relative;
    top: 0;
    opacity: 1;
    top: 10rem;
    padding-left: 24vw;
}

/* Adapt for an ipad */
@media screen and (min-width: 768px) and (max-width: 1220px) {
	.annotation-next-container {
		padding-left: 18vw;
	}
}

.next-arrow {
    position: relative;
	top: -7rem;
	right: 10rem;
}

.custom-story-button {
	margin-left: 4rem;
	margin-top: 4rem;
	cursor: pointer;
	z-index: 10;
}

.custom-story-button img {
	height: 7rem;
	width: 7rem;
}

.navigation-line-container-full-width {
    position: relative;
	display: flex;
	flex-flow: row nowrap;
    width: 103vw;
	left: -1vw;
	z-index: 10;
}

.navigation-line-full-width {
	background: var(--esa-light-colour);
	height: 1.2rem;
	width: 100vw;
	position: relative;
	left: -1rem;
    z-index: 0;
}

.navigation-line-full-column-width {
	background: var(--esa-light-colour);
	height: 1.2rem;
	width: 110%;
	position: relative;
	left: -1rem;
    z-index: 0;
}

.navigation-line-circle-full-width {
	background: var(--esa-secondary-colour);
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 100%;
	position: relative;
	left: 70rem;
	bottom: 1rem;
	z-index: 15;
}

.navigation-line-circle-full-width-height {
	background: var(--esa-secondary-colour);
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 100%;
	position: relative;
	z-index: 2;
}

.navigation-line-halfright-container {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	width: 100vw;
}

.navigation-line-circle {
	background: var(--esa-secondary-colour);
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 100%;
	position: relative;
	left: 2rem;
	bottom: 1rem;
	z-index: 3;
}

.navigation-line {
	background: var(--esa-light-colour);
	height: 1.2rem;
	width: 50vw;
}

.navigation-line-end {
	background: var(--esa-light-colour);
	height: 1.2rem;
	width: 50vw;
	left: -50vw;
	position: relative;
}