@import url('../../App.css');

header {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	background: var(--esa-primary-colour);
	width: 100%;
	padding: 2rem;
	height: 7.5vh;
}

header img {
	width: 4.131rem;
	height: 3.869rem;
	margin: 0 4rem 0 4rem;
	cursor: pointer;
}

header a {
	margin: 0 2rem 0 2rem;
	display: block;
	color: var(--esa-light-colour);
	text-decoration: none;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	letter-spacing: 0.3rem;
}

header a:hover,
a:visited {
	color: var(--esa-light-colour);
}

header hr {
	margin-top: 0.5rem;
	border: 0.14rem solid var(--esa-light-colour);
	width: 18.188rem;
}

.nav-links p {
	color: var(--esa-light-colour);
	cursor: pointer;
}

.selected {
	padding-bottom: 2px;
	border-bottom: solid white 4px;
}
