@import url('../../App.css');
.toggle-modal {
	/* background: rgba(0, 0, 0, 0.7); */
	height: auto;
	/* width: 40rem; */
	display: flex;
	flex-flow: row nowrap;
	align-content: flex-end;
	text-align: center;
	font-size: 1.6rem;
	cursor: pointer;
}

.toggle-modal p {
	color: var(--esa-light-colour);
}

.toggle-modal span {
	font-family: 'Notes-Esa-Bold';
	color: var(--esa-interactive-colour);
}

.box1 {
	position: relative;
	background: rgba(0, 0, 0, 0.7);
	width: 33rem;
	height: auto;
	left: 3rem;
	bottom: -0.5rem;
}

.box1 p {
	font-size: 2.4rem;
	width: 33rem;
	font-family: 'Notes-Esa';
	padding: 1rem;
}

.box2 {
	background: rgba(0, 0, 0, 0.7);
	position: relative;
	bottom: 19rem;
	width: 50rem;
	left: -5rem;
}

.box2 h1 {
	font-size: 3rem;
	font-family: 'Notes-Esa-Reg';
	padding: 1rem;
}

.box2 p {
	font-family: 'Notes-Esa-Reg';
	font-size: 3rem;
	text-align: left;
	line-height: 4rem;
	padding: 3rem;
}

.chevron-container {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.chevron-white-box-horizontal {
	background: white;
	height: 0.1rem;
	width: 1.5rem;
}

.chevron-white-box-vertical {
	background: white;
	height: 1rem;
	width: 0.1rem;
}

.modal-button-globe-container {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
}

.modal-button-globe-container img {
	width: 4rem;
	margin-right: 1rem;
}
