.arrow-exit-story-container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.arrow-exit-story-container img {
	position: relative;
	top: -3rem;
	left: 0rem;
	width: 15rem;
	transform: scaleX(-1);
}

.arrow-exit-story p {
	color: #a1bfbc;
	letter-spacing: 0.2rem;
	text-align: center;
	text-transform: uppercase;
	font-size: var(--paragraph-size);
	position: relative;
	top: -2rem;
	right: 2rem;
	font-family: var(--esa-regular-font);
}
