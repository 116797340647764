@import url('../../App.css');

:root {
	--module-button-radius: 5rem;
}

/*
@media screen and (min-width: 768px) and (max-width: 1920px) {
    :root {
        --module-heading-offset: 20vw;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2600px) {
    :root {
        --module-heading-offset: 25vw;
    }
}

@media screen and (min-width:2601px) {
    :root {
        --module-heading-offset: 24vw;
    }
}
*/


.module-container {
	color: var(--esa-light-colour);
	border-radius: var(--module-button-radius);
	width: 35vw;
	height: 41.5vw;
	margin-right: 15rem;
	cursor: pointer;
	overflow: hidden;
}

.module-image-bg {
	position: relative;
	z-index: 1;
	height: 70vh;
}

/*
Module One Image is 1300 * 1589, so ratio 1.22

A 16/9 screen is 0.56 as tall as wide. We can specify units in height or pixels
for a 16/9 screen 35vw ~ 62vh.

If I would like to clamp the incoming image to ~60vh.
If 1600 is 60vh, then 1300 is 48.75vh or 27.5vw...

If I'd like to clamp it to 34.5vw (offsets) then that means my final image will be
34.5vw -> 42vw high.
*/

.module-image-bg img {
	width: 100%;
	max-width: 34.5vw;
	position: absolute;
	z-index: 1;
	border-radius: var(--module-button-radius);
}

.module-background-shadow {
	position: relative;
	z-index: 0;
	top: 1vh; /*1.9rem;*/
	left: 1vw; /*1.5rem;*/
	border-radius: var(--module-button-radius);
}

.module-content {
	position: relative;
	z-index: 1;
	top: 20vw;
	height: 22.5vw;
	border-radius: var(--module-button-radius);
}

.module-details {
	position: absolute;
	bottom: 0px;
	padding: 0vw 2vw 3vw 2vw;
}

.module-content p {
	font-size: 4rem;
	font-family: var(--esa-bold-font);
}

.module-content h1 {
	font-size: 5rem;
	margin: 1vh 0 1vh 0;
	font-family: var(--esa-bold-font);
}

.description-container {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	width: 28vw;
}

.description-container p {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 4rem;
	margin-right: 1vw;
	flex: 10;
}

.description-container svg {
	width: 5rem;
	height: 5rem;
	position: relative;
	top: 0rem;
	left: 4rem;
}

.disabled-module {
	color: #a2a2a2;
	fill: #a2a2a2;
}

.active-module {
	color: var(--esa-light-colour);
	fill: var(--esa-light-colour);
}

.active-module-description {
	color: var(--esa-light-minus-1-colour);
	fill: var(--esa-light-minus-1-colour);
}
