/* ESA Fonts */
@font-face {
	font-family: 'Notes-Esa-Reg';
	src: url('../src/fonts/Notes-Esa-Reg.otf') format('opentype');
}

@font-face {
	font-family: 'Notes-Esa-RegIta';
	src: url('../src/fonts/Notes-Esa-RegIta.otf') format('opentype');
}

@font-face {
	font-family: 'Notes-Esa';
	src: url('../src/fonts/Notes-Esa.woff2');
}

@font-face {
	font-family: 'Notes-Esa-Bold';
	src: url('../src/fonts/Notes-Esa-Bold.woff2');
}

@font-face {
	font-family: 'Notes-Esa-Italic';
	src: url('../src/fonts/Notes-Esa-Italic.woff2');
}

@font-face {
	font-family: 'Notes-Esa-BoldItalic';
	src: url('../src/fonts/Notes-Esa-BoldItalic.woff2');
}
/* ESA Fonts / */

:root {
	--esa-placeholder-colour: #676c70;
	--esa-dark-minus-1-colour: #545759;
	--esa-disabled-colour: #aaafb3;
	--esa-secondary-plus-2-colour: #006762;
	--esa-secondary-plus-1-colour: #008e7a;
	--esa-light-minus-1-colour: #d6d6d6;
	--esa-primary-plus-2-colour: #8197a6;
	--esa-primary-plus-1-colour: #335e6f;
	--esa-secondary-colour: #00ae9d;
	--esa-secondary-minus-1-colour: #76c8ae;
	--esa-dark-colour: #000000;
	--esa-light-colour: #ffffff;
	--esa-primary-colour: #003247;
	--esa-interactive-colour: #faab1a;

	--esa-regular-font: 'Notes-Esa-Reg';
	--esa-bold-font: 'Notes-Esa-Bold';
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    :root {
        --root-font-size: 8px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1920px) {
    :root {
        --root-font-size: 10px;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2600px) {
    :root {
        --root-font-size: 10px;
    }
}

/* TV: TO BE FIXED */
@media screen and (min-width:2601px) {
    :root {
        --root-font-size: 10px;
    }
}

html {
	font-size: var(--root-font-size);
}

body {
	font-size: 3rem;
	font-family: 'Notes-Esa-Reg', Arial, Helvetica, sans-serif;
	background: var(--esa-light-minus-1-colour);
}

.show {
	display: block;
}

.hide {
	display: none;
}

main {
	padding: 5rem;
}

/** Header is 7.5vh */

.home-intro-section-container {
	position: relative;
	top: 44vh;
	height: 56vh;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: space-between;
	z-index: 2;
}

.module-section-container {
	position: relative;
	z-index: 2;
	top: 50vh;
	left: 2.5vw;
	padding-top: 5vh;
	height: 80vh;
	width: 95vw;

	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
}

.logo-section-container {
	position: relative;
	z-index: 2;
	top: 50vh;
	height: 15vh;
	right: 13rem;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-end;
	overflow: hidden;
}

.intro-container {
	display: flex;
	flex-flow: row nowrap;
	width: 95vw;
	left: 2.5vw;
	height: 50vh;
	max-width: 200rem;
	position: relative;
	bottom: 4rem;
}

.divider {
	background-color: var(--esa-secondary-colour);
	height: 40rem;
	width: 1rem;
	margin-right: 5rem;
}

.intro-container img {
	width: 100%;
	max-width: 135rem;
}

.intro-container h1 {
	color: var(--esa-secondary-plus-1-colour);
	margin: 4rem 0 4rem;
	font-size: 4.5rem;
	font-family: 'Notes-Esa-Reg';
}

.intro-container p {
	color: var(--esa-primary-colour);
	font-family: Arial, Helvetica, sans-serif;
	font-size: 3.6rem;
	letter-spacing: 0.2rem;
}



.find-out-more-heading {
	text-transform: uppercase;
	color: var(--esa-secondary-colour);
	font-family: 'Notes-Esa-Bold';
	font-size: 4rem;
	margin-right: 5vw;
}


