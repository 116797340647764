.volume {
	position: absolute;
	top: 1rem;
	left: 2rem;
}

.volume img {
	width: 100%;
	max-width: 4rem;
}
