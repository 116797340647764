.esa-logo-container {
	position: relative;
	bottom: 5rem;
	left: 4rem;
}

.esa-logo-container img {
	max-width: 100%;
	width: 30rem;
}
