@import url('../../App.css');

@media screen and (min-width: 768px) and (max-width: 1220px) {
    :root {
		--modal-height: 90vh;
		--modal-width: 90vw;
		--max-cube-display-width: 65vw;
		--max-description-width: 20vw;
	}
}
@media screen and (min-width: 1220px) and (max-width: 1920px) {
    :root {
		--modal-height: 80vh;
		--modal-width: 80vw;
		--max-cube-display-width: 65vw;
		--max-description-width: 20vw;
	}
}
@media screen and (min-width: 1921px) and (max-width: 2600px) {
    :root {
		--modal-height: 70vh;
		--modal-width: 70vw;
		--max-cube-display-width: 55vw;
		--max-description-width: 20vw;
	}
}
/* TV: TO BE FIXED */
@media screen and (min-width:2601px) {
    :root {
		--modal-height: 70vh;
		--modal-width: 70vw;
		--max-cube-display-width: 95vw;
		--max-description-width: 20vw;
	}
}

.overlay {
	background: rgba(0, 50, 71, 0.8);
	width: 100%;
	height: 100vh;
	position: relative;
	z-index: 10;
}

.data-cube-modal-container {
	background: var(--esa-primary-colour);
	border: 1rem solid var(--esa-light-colour);
	padding: 2.5vw;
	color: var(--esa-light-colour);
	margin: 0 auto;
	height: var(--modal-height);
	width: var(--modal-width);
	max-height: var(--modal-height);
	max-width: var(--modal-width);
	position: relative;
	top: calc((100vh - var(--modal-height))/2);
	display: flex;
	justify-content: center;
}
.data-cube-content {
	margin-left: -5vw;
}

.data-cubes {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	max-width: var(--max-cube-display-width);
	width: 75vw;
}

.data-cubes div div {
	margin-bottom: 2.5rem;
}

.data-cubes-6,
.data-cubes-12,
.data-cubes-18,
.data-cubes-24 {
	text-align: center;
}

.data-cubes-6 img,
.data-cubes-12 img,
.data-cubes-18 img,
.data-cubes-24 img {
	height: 7.5vw;
	width: 7.5vw;
}

.data-cubes-6 h1,
.data-cubes-12 h1,
.data-cubes-18 h1,
.data-cubes-24 h1 {
	font-family: 'Notes-Esa-Bold';
	font-size: 3rem;
	margin-bottom: 2rem;
}

.data-cubes-description {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-evenly;
	width: 100%;
	max-width: var(--max-description-width);
}

.data-cubes-description img {
	width: 100%;
	max-width: 38rem;
}

.data-cubes-description h1 {
	font-size: 3rem;
	line-height: 5rem;
}

.data-cubes-description p {
	margin-top: 2rem;
	font-size: 1.8rem;
}

.data-cube-legend-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
}

.data-cube-legend img {
	width: 30rem;
}

.data-cube-cargo-ship-icon {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	width: 100%;
}

.data-cube-cargo-ship-icon img {
	width: 20rem;
}

.close-modal-container {
	position: absolute;
	top: 0;
	right: 10rem;
}

.close-modal-exit-button {
	position: relative;
	top: 4rem;
	left: 11rem;
	cursor: pointer;
	width: 2rem;
	height: 2rem;
}

.close-modal-close-arrow {
	position: relative;
	left: 2rem;
	top: 2rem;
}

.close-modal-close-arrow img {
	width: 4rem;
}

.close-modal-close-arrow p {
	font-size: 2rem;
}

.open-modal-box-area {
	cursor: pointer;
	background: yellow;
	position: absolute;
	z-index: 1;

	/* To make the clickable area visible/invisible for debugging */
	opacity: 0;

	/* Invisible clickable box rectangle area: note this basically assumes a 16:9 aspect ratio */
	left: 32vw;
	width: 10.5vw;
	height: 10.5vw;
	bottom: 8vw;
}

@media screen and (min-width: 768px) and (max-width: 1220px) {
	.open-modal-box-area {
		/* Invisible clickable box rectangle area: note this basically assumes a 4:3 aspect ratio */
		left: 26vw;
		width: 14vw;
		height: 14vw;
		bottom: 9vw;
	}
}