@import url('../../App.css');

.home-background-container {
	background: var(--esa-light-minus-1-colour);
	height: 100%;
	width: 100%;
	position: absolute;
}

@media screen and (min-width: 768px) and (max-width: 1220px) {
	.home-background-ocean {
		height: 100%;
		width: 100%;
		background-image: url("../../img/ocean-1080.png");
		background-repeat: no-repeat;
	}
}

@media screen and (min-width: 1220px) and (max-width: 1920px) {
	.home-background-ocean {
		height: 100%;
		width: 100%;
		background-image: url("../../img/ocean-1440.png");
		background-repeat: no-repeat;
	}
}

@media screen and (min-width: 1921px) and (max-width: 2600px) {
	.home-background-ocean {
		height: 100%;
		width: 100%;
		background-image: url("../../img/ocean-1440.png");
		background-repeat: no-repeat;
	}
}

@media screen and (min-width:2601px) {
	.home-background-ocean {
		height: 100%;
		width: 100%;
		background-image: url("../../img/ocean.png");
		background-repeat: no-repeat;
	}
}