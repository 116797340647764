.close-arrow-container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.close-arrow-container img {
	position: relative;
	top: 2rem;
	left: 1.5rem;
	width: 7rem;
}

.close-arrow p {
	color: #a1bfbc;
	letter-spacing: 0.2rem;
	text-align: center;
	text-transform: uppercase;
	font-size: 3rem;
	margin-top: 2rem;
	font-family: 'Notes-Esa-Reg';
}
