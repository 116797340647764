.arrow-navigate-story-container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.arrow-navigate-story-container img {
	position: relative;
	left: 5vw;
	width: 7rem;
}

.arrow-navigate-story p {
	color: #a1bfbc;
	letter-spacing: 0.2rem;
	text-align: center;
	text-transform: uppercase;
	font-size: var(--paragraph-size);
	margin-top: 1rem;
	font-family: var(--esa-regular-font);
}
