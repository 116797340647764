
@media screen and (min-width: 768px) and (max-width: 1220px) {
    :root {
        --heading-size: 4rem;
		--paragraph-size: 3rem;
        --narrow-content-width: 40vw;
		--narrower-content-width: 50vw;
		--content-width: 50vw;
		--wider-content-width: 65vw;
        --top-content-offset: 3vh;
		--top-content-offset-with-header: 10.5vh;

		--slide3-modal-width-offset: 5vw;
		--slide3-modal-height-offset: 17vh;
		--slide3-modal-line-offset: 60vw;
		--slide3-line-width: 25vw;

		--slide-6-column-width: 40vw;
		--slide-6-down-arrow-left-offset: 21vw;

		--slide11-modal-height-offset: 23vh;
		--slide11-modal-width-offset: 11vw;
		--slide11-line-height: 22vh;
		--slide11-line-top-offset: -58vh;
		--slide11-line-left-offset: 80vw;

		--slide12-modal-height-offset: 23vh;
		--slide12-modal-width-offset: 18vw;
		--slide12-line-width: 20vw;
		--slide12-line-top-offset: -10vh;
		--slide12-line-left-offset: 53vw;

		--bar-height:60vh;
		--bar-offset:8vh;

		--globe-size: 22vw;
		--globe-margin-right: 15vw;
    }
}

@media screen and (min-width: 1220px) and (max-width: 1920px) {
    :root {
        --heading-size: 6rem;
		--paragraph-size: 3rem;
        --narrow-content-width: 40vw;
		--narrower-content-width: 50vw;
		--content-width: 60vw;
		--wider-content-width: 70vw;
        --top-content-offset: 3vh;
		--top-content-offset-with-header: 10.5vh;

		--slide3-modal-width-offset: 5vw;
		--slide3-modal-height-offset: 17vh;
		--slide3-modal-line-offset: 60vw;
		--slide3-line-width: 25vw;

		--slide-6-column-width: 40vw;
		--slide-6-down-arrow-left-offset: 21vw;

		--slide11-modal-height-offset: 23vh;
		--slide11-modal-width-offset: 11vw;
		--slide11-line-height: 22vh;
		--slide11-line-top-offset: -58vh;
		--slide11-line-left-offset: 80vw;

		--slide12-modal-height-offset: 23vh;
		--slide12-modal-width-offset: 18vw;
		--slide12-line-width: 20vw;
		--slide12-line-top-offset: -10vh;
		--slide12-line-left-offset: 53vw;

		--bar-height:60vh;
		--bar-offset:8vh;

		--globe-size: 22vw;
		--globe-margin-right: 15vw;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2600px) {
    :root {
        --heading-size: 7rem;
		--paragraph-size: 4rem;
        --content-width: 50vw;
		--narrow-content-width: 40vw;
		--narrower-content-width: 40vw;
		--wider-content-width: 60vw;
        --top-content-offset: 5vh;
		--top-content-offset-with-header: 12.5vh;

		--slide3-modal-width-offset: 15vw;
		--slide3-modal-height-offset: 18vh;
		--slide3-modal-line-offset: 52vw;
		--slide3-line-width: 70rem;

		--slide-6-column-width: 30vw;
		--slide-6-down-arrow-left-offset: 23vw;

		--slide11-modal-width-offset: 15vw;
		--slide11-modal-height-offset: 18vh;
		--slide11-line-height: 30vh;
		--slide11-line-top-offset: -68vh;
		--slide11-line-left-offset: 76vw;

		--slide12-modal-height-offset: 27vh;
		--slide12-modal-width-offset: 18vw;
		--slide12-line-width: 20vw;
		--slide12-line-top-offset: -10vh;
		--slide12-line-left-offset: 53vw;



		--bar-height:60vh;
		--bar-offset:10vh;

		--globe-size: 23vw;
		--globe-margin-right: 15vw;
    }
}

/* TV: TO BE FIXED */
@media screen and (min-width:2601px) {
    :root {
        --heading-size: 7rem;
		--paragraph-size: 4rem;
        --content-width: 40vw;
		--narrow-content-width: 40vw;
		--narrower-content-width: 35vw;
		--wider-content-width: 50vw;
        --top-content-offset: 5vh;
		--top-content-offset-with-header: 12.5vh;

		--slide3-modal-width-offset: 15vw;
		--slide3-modal-height-offset: 18vh;
		--slide3-modal-line-offset: 52vw;
		--slide3-line-width: 70rem;

		--slide-6-down-arrow-left-offset: 23vw;

		--slide11-modal-width-offset: 15vw;
		--slide11-modal-height-offset: 18vh;
		--slide11-line-height: 30vh;
		--slide11-line-top-offset: -68vh;
		--slide11-line-left-offset: 76vw;

		--slide12-modal-height-offset: 27vh;
		--slide12-modal-width-offset: 18vw;
		--slide12-line-width: 20vw;
		--slide12-line-top-offset: -10vh;
		--slide12-line-left-offset: 53vw;

		--bar-height:60vh;
		--bar-offset:10vh;

		--globe-size: 60rem;
		--globe-margin-right: 15vw;
	}
}

.slide-container {
    position: relative;
    overflow: hidden;
	height: 100vh;
	width: 100vw;
}

.slide-top-half-container {
    background: var(--esa-primary-colour);
    height: 48vh;
}

.pol-logo {
	margin-top: 2vh;
}

.slide-top-half-container-no-background {
	height: 48vh;
}

.slide-top-half-container-with-image {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 48vh;
}

.slide-bottom-half-container {
    height: 52vh;
    position: relative;
	background-repeat: no-repeat;
	background-position: center;
}

.slide-container > .bg-image {
	height: 100vh;
	width: 100vw;
}

/**
 * Introduction
 */

.slide_introduction {
	width: 100vw;
	text-align: center;
	height: 20vh;
	position: relative;
	top: -22rem;
    color: var(--esa-light-colour);
}

.slide_end {
	width: 100vw;
	text-align: center;
	height: 10vh;
	position: relative;
	top: -14rem;
    color: var(--esa-light-colour);
}

.slide_introduction h1, .slide_end h1 {
	font-size: 6rem;
	position: relative;
	z-index: 10;
	font-family: var(--esa-bold-font);
}

.slide_introduction h2 {
	font-family: var(--esa-regular-font);
	text-transform: uppercase;
	font-size: 6rem;
}

.slide_introduction p {
	text-transform: uppercase;
	font-size: 3rem;
	letter-spacing: 0.3rem;
	font-family: var(--esa-regular-font);
}

/**
 * Slide Story Content - top half
 */

/* slide 2, 3 */
.slide-top-content {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.slide-top-content-text-container {
    color: var(--esa-light-colour);
	margin: var(--top-content-offset) 5vw;
	width: var(--content-width);
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.slide-top-wider-text {
	width: var(--wider-content-width);
}

.slide-top-narrower-text {
	width: var(--narrower-content-width);
}

.slide-top-very-narrow-text {
	width: var(--narrow-content-width);
}

.slide-top-content-text-container img {
	width: 50rem;
	margin-bottom: 1rem;
}

.slide-top-content-text-container h1 {
	font-family: var(--esa-regular-font);
	font-size: var(--heading-size);
    margin: 4rem 0 4rem 0;
}

.slide-top-content-text-container p {
	font-family: Arial, Helvetica, sans-serif;
	font-size: var(--paragraph-size);
}

.slide-top-content-text-container span {
	color: var(--esa-secondary-minus-1-colour);
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
}

.slide-top-content-text-container strong {
	color: var(--esa-secondary-minus-1-colour);
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
}

/* Columns */
.slide-floating-header {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	z-index: 1;
}

.slide-column-container {
	width:100%;
	display: flex;
	flex-direction: row;
}
.slide-column-one {
	width: var(--slide-6-column-width);
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.slide-column-one .top {
	height: 48vh;
}

.slide-column-one .slide-top-content-text-container {
    color: var(--esa-light-colour);
	margin: var(--top-content-offset-with-header) 2.5vw;
	width: calc(var(--slide-6-column-width) - 5vw);
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.slide-column-one .bottom {
	height: 52vh;
	border-top: 1.2rem white solid;
	border-right: 1.2rem white solid;
}
.slide-column-two  {
	width: calc(100vw - var(--slide-6-column-width));
	height: 100vh;
	display: flex;
}

.bordered {
	border-right: 1.2rem white solid;
}

.slide-column-container-first-row {
	width:100%;
	display: flex;
	flex-direction: row;
	height: 48vh;
}
.slide-column-two-boundary  {
	width: calc(100vw - var(--slide-6-column-width));
	height: 100%;
}

/* Slide specific navigation */
.slide-6-circle {
	position: absolute;
	top: 47vh;
	left: 27vh;
}

.slide-6-left-arrow {
	position: absolute;
	top: calc(48vh + 1rem);
	left: 5rem;
}

.slide-6-down-arrow {
	position: absolute;
	bottom: 5rem;
	left: calc(var(--slide-6-column-width) - 18rem);
}

.slide-7-circle {
	position: absolute;
	top: calc(47vh);
	left: calc(var(--slide-6-column-width) + 5vw);
}

.slide-7-up-arrow {
	position: absolute;
	top: 5rem;
	left: calc(var(--slide-6-column-width) - 18rem);
}

/* Adapt for an ipad */
@media screen and (min-width: 768px) and (max-width: 1220px) {
	.pol-logo {
		margin-top: 4vh;
	}

	.slide-7-up-arrow {
		position: absolute;
		top: 5rem;
		left: calc(var(--slide-6-column-width) + 5rem);
	}
}

.slide-7-right-arrow {
	position: absolute;
	top: calc(48vh + 1rem);
	right: 5rem;
}

.slide-7-navigation-line {
	background: var(--esa-light-colour);
	height: 1.2rem;
	width: calc(100vw - var(--slide-6-column-width));
	position: relative;
	left: var(--slide-6-column-width);
	z-index: 0;
}

/* Slide modal containers */
.slide3-modal-container {
	position: relative;
	top: var(--slide3-modal-height-offset);
	right: var(--slide3-modal-width-offset);
	z-index: 6;
}

.slide3-find-out-more-line {
	position: relative;
	top: calc(-1 * var(--slide3-modal-height-offset));
	left: var(--slide3-modal-line-offset);
	z-index: 1;
}

.slide3-find-out-more-line img {
	width: var(--slide3-line-width);
}

.slide11-modal-container {
	position: relative;
	top: var(--slide11-modal-height-offset);
	right: var(--slide11-modal-width-offset);
	z-index: 6;
}

.slide11-find-out-more-line {
	position: relative;
	top: var(--slide11-line-top-offset);
	left: var(--slide11-line-left-offset);
	z-index: 1;
}

.slide11-find-out-more-line img {
	height: var(--slide11-line-height);
}

.slide12-modal-container {
	position: relative;
	top: var(--slide12-modal-height-offset);
	right: var(--slide12-modal-width-offset);
	z-index: 6;
}

.slide12-find-out-more-line {
	position: relative;
	top: var(--slide12-line-top-offset);
	left: var(--slide12-line-left-offset);
	z-index: 1;
}

.slide12-find-out-more-line img {
	width: var(--slide12-line-width);
	height: var(--slide12-line-height);

}

.slide9-data-cube-modal-overlay-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



/** Specific Slide Images */
.story-content-info-globe {
	position: absolute;
	bottom: 52vh;
	right: var(--globe-margin-right);
}
.story-content-info-globe img {
	height: var(--globe-size);
	width: var(--globe-size);
}

.story-content-info-bar {
	position: absolute;
	bottom: 23vh;
	right: var(--globe-margin-right);
}
.story-content-info-bar img {
	height: var(--bar-height);
}

/** Slide bottom images */
.slide-bottom-content-image {
	position: absolute;
	height: 52vh;
	width: 100vw;
	top: 48vh;
	background-position: center;
}

.opacity-50 {
	opacity: 0.5;
}

.opacity-20 {
	opacity: 0.2;
}